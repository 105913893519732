import * as React from "react";
import {FC} from "react";
import {useVoucherEnableDisableMutation, useVoucherPanelVoucherQuery} from "../../../Queries";
import {BodyText, TitleText} from "../../../layout/Typography";
import {Box, Checkbox, FormControlLabel, Grid, LinearProgress} from "@mui/material";
import {DateFormat} from "../../../utility/DateFormat";
import {SavingUI} from "../../../SavingProvider";

type VoucherPanelProps = {
   voucherId: string
}

export const VoucherPanel: FC<VoucherPanelProps> = ({voucherId}) => {
   const {data} = useVoucherPanelVoucherQuery({
      variables: {
         id: voucherId
      }
   })
   const [voucherEnableDisable] = useVoucherEnableDisableMutation();

   const onEnableDisable = async () => {
      await SavingUI.process(async () => {
         await voucherEnableDisable({
            variables: {
               voucherId: voucherId,
               enable: !!voucher.disabled
            }
         })
      }, voucher.disabled ? 'Enabled' : 'Disabled', 'Please wait')
   }

   if (!data) {
      return <LinearProgress/>
   }
   const voucher = data.VoucherById!;

   return <Grid container rowGap={2}>
      <Grid item xs={6}>
         <TitleText type={"h1"}>{voucher.codeAlias || voucher.code}</TitleText>
         {(voucher.codeAlias && voucher.code !== voucher.codeAlias) &&
             <TitleText type={"subtitle1"}>Alias for {voucher.code}</TitleText>}
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>Value</TitleText>
         <BodyText type={"body1"}>
            Original: {voucher.value} {voucher.currency}
         </BodyText>
         <BodyText type={"body1"}>
            Remaining: {Math.round((voucher.currentValue || 0) * 10) / 10} {voucher.currency}
         </BodyText>
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>
            Activated</TitleText>
         <Box sx={{display: 'inline-block'}}>
            <BodyText style={{display: 'inline'}} type={"body1"}>{voucher.activated ? "Yes" : "No"}</BodyText>
         </Box>
         &nbsp;({voucher.voucherStrategy})
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>
            Expiration
         </TitleText>
         <BodyText type={"body1"}>{voucher.expireDate ? DateFormat.toPresent(voucher.expireDate) : 'Never'}</BodyText>
      </Grid>
      <Grid item xs={6}>
         <TitleText type={"h2"}>
            Minimum purchase
         </TitleText>
         <BodyText
            type={"body1"}>{voucher.minimumPurchase ? (voucher.minimumPurchase + ' ' + voucher.currency) : '-'}
         </BodyText>
      </Grid>
      <Grid item xs={6}>
         <FormControlLabel
            control={<Checkbox checked={!voucher.disabled} value={true} onChange={onEnableDisable}/>}
            label={'Voucher enabled'}
         />
         {((voucher.voucherPdf || []).length > 0) && <Grid>
             <TitleText type={"h2"}>
                 Voucher PDFs
             </TitleText>
             <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {voucher.voucherPdf!.map((pdf) => (<Box>
                      <a href={pdf!.url!} target={"_blank"} rel={"nofollow noreferrer"}>{pdf?.language || '??'}:
                         Download </a>
                   </Box>
                ))}
             </Box>
         </Grid>}

      </Grid>
   </Grid>
}