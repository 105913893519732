import * as React from "react";
import {Alert, Box, Chip} from "@mui/material";
import {styled} from "@mui/styles";
import dayjs from "dayjs";
import {FlexListColumn} from "../../components/flexlist/FlexTypes";
import {BrandItemAggregatedStateEnum, FlexBrandItemFragment, LocalizationEntry, Pricing} from "../../Queries";
import {Thumbnails} from "../../components/Thumbnails";
import {Barcoder} from "../../utility/BarcodeValidate";
import {StringHelper} from "../../utility/StringHelper";
import {startCase} from "lodash";
import {Thumbnail} from "../../components/Thumbnail";
import {CheckBox, CheckBoxOutlineBlankOutlined, CheckBoxOutlined} from "@mui/icons-material";
import {BrandItemMasterDataStatus} from "./BrandItemMasterDataStatus";
import {BrandItemTakeActionColumn} from "./BrandItemTakeActionColumn";

export enum ShowingType {
   SALE = 'forSale',
   REVIEW = 'needReview',
   DRAFT = 'draft',
   SOLD = 'sold',
   ALL = 'all'
}

type ItemColumn = Omit<FlexListColumn<FlexBrandItemFragment>, 'width'>

const ColID: ItemColumn = {
   id: 'id',
   label: 'Id',
   field: 'itemNr'
};

const ColBrand: ItemColumn = {
   id: 'brand',
   label: 'Brand',
   field: 'brandHouseId',
   render: (name, row) => {
      return <div>{row?.brandHouse?.name}</div>
   },
   ellipsis: true
};

const ColLocationTS: ItemColumn = {
   id: 'stockLocationTs',
   label: 'Layaway',
   field: 'stockLocationTs',
   render: (time) => {
      if (time) {
         return <>{dayjs(time, {}).fromNow()}</>
      } else {
         return "-"
      }
   }
};

const ColShops: ItemColumn = {
   id: 'activeshops',
   label: 'Shops',
   field: 'itemState',
   render: (data, row, context) => {
      return <Box>{(context.shops||[]).map((shop: any) => {
         if(row?.shops?.find(shp => String(shp?.shopId) === String(shop._id))?.active) {
            return <CheckBox/>
         } else {
            return <CheckBoxOutlineBlankOutlined/>
         }
      })}</Box>
   }
};

const ColImage: ItemColumn = {
   id: 'image',
   label: 'Image',
   get: (stockItem) => {
      return stockItem.imageUrls || []
   },
   render: (images: string[]) => {
      return <Thumbnails maxImages={3} size={50} imageUrls={images}/>
   }
};

const ColImageExtended: ItemColumn = {
   id: 'imageExtended',
   label: 'Images',
   get: (stockItem) => {
      return (stockItem.images || []).filter(img => !!img!.imageId).map(img => img!.imageUrl)
   },
   render: (images: string[]) => {
      // Get the first 2 images from the array
      let filteredImages = images.slice(0, 2);
      return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
         {images.length < 3 && filteredImages.map((image) => <Thumbnail key={"thimg_" + image} image={image}
                                                                        size={100}/>)}
         {images.length > 2 && <Thumbnail key={"thimg_" + images[0]} image={images[0]} size={100}/>}
         {images.length > 2 && <Thumbnails legend={"+" + (images.length - 1)} imageUrls={images.splice(1)} size={100}/>}
      </Box>
   }
};


const ColBeforePrice: ItemColumn = {
   id: 'beforePrice',
   field: 'beforePrices',
   label: 'RRP',
   render: (price: Pricing[] | undefined, stockItem: FlexBrandItemFragment | undefined, context) => {
      let prices = price?.filter(pr => pr.value) || [];
      if (prices.length === 0) {
         return <Chip label={"Missing"}/>
      }

      prices = prices.sort((a, b) => StringHelper.compare(a.currency, b.currency))

      return <div>{prices.slice(0, 3).map(pr => {
         return <div>{pr?.value + ' ' + pr?.currency}</div>
      })}</div>
   }
}

const ColStatus: ItemColumn = {
   id: 'status',
   label: 'Status',
   field: 'stateAggregation',
   align: 'center',
   render: (status, stockItem) => {
      const itemState: BrandItemAggregatedStateEnum = status;

      let warnings: BrandItemAggregatedStateEnum[] = [
         BrandItemAggregatedStateEnum.NONE,
         BrandItemAggregatedStateEnum.BLOCKED,
         BrandItemAggregatedStateEnum.NOTFORSALE,
         BrandItemAggregatedStateEnum.NOTFORSALE,

      ]
      let errors: BrandItemAggregatedStateEnum[] = [
         BrandItemAggregatedStateEnum.MISSING,
         BrandItemAggregatedStateEnum.NOLOCATION
      ]
      let success: BrandItemAggregatedStateEnum[] = [
         BrandItemAggregatedStateEnum.SOLD
      ]

      const getTitle = () => {
         if (itemState === BrandItemAggregatedStateEnum.FORSALE) {
            return "For sale"
         } else if (itemState === BrandItemAggregatedStateEnum.FORSALEPARTLY) {
            return "For sale (partly)"
         } else if (itemState === BrandItemAggregatedStateEnum.NOLOCATION) {
            return "No location"
         } else if (itemState === BrandItemAggregatedStateEnum.NOTFORSALE) {
            return "Not for sale"
         } else if (itemState === BrandItemAggregatedStateEnum.OUTOFSEASON) {
            return "Of out season"
         } else {
            if (itemState) {
               return startCase(itemState.toLowerCase());
            } else {
               return "Required";
            }
         }
      }

      if (warnings.includes(itemState)) {
         return <AlertLabel severity={"warning"}>{getTitle()}</AlertLabel>
      } else if (errors.includes(itemState)) {
         return <AlertLabel severity={"error"}>{getTitle()}</AlertLabel>
      } else if (success.includes(itemState)) {
         return <AlertLabel severity={"success"}>{getTitle()}</AlertLabel>
      } else {
         return <AlertLabel severity={"info"}>{getTitle()}</AlertLabel>
      }
   },
}

const ColComment: ItemColumn = {
   id: 'comment',
   label: 'Comment',
   field: 'commentLocalized',
   render: (status, stockItem) => {
      if (stockItem?.commentLocalized) {
         return <>{stockItem.commentLocalized.map(comment => {
            return <div>{comment?.locale + ': ' + comment?.value}</div>
         })}</>
      } else {
         return <Chip label={"Missing"}/>
      }
   }
}


const ColCommentApproved: ItemColumn = {
   id: 'commentApproved',
   label: 'Approved',
   field: 'commentApproved',
   render: (status, stockItem) => {
      if (stockItem?.commentApproved) {
         return <CheckBoxOutlined/>
      } else {
         return <CheckBoxOutlineBlankOutlined/>
      }
   }
}

const ColCommentTranslated: ItemColumn = {
   id: 'commentTranslated',
   label: 'Translated',
   field: 'hasTranslatedComment',
   render: (status, stockItem, context) => {
      const locales = context.locales as string[] || [];
      const localizedComments = stockItem?.commentLocalized || [];
      const hasTranslatedComment = localizedComments.length > 0 && locales.every(locale => {
         return !!localizedComments.find(comment => comment?.locale === locale)?.value
      })
      if (hasTranslatedComment) {
         return <CheckBoxOutlined/>
      } else {
         return <CheckBoxOutlineBlankOutlined/>
      }
   }
}

const ColStockLocation: ItemColumn = {
   id: 'stockLocation',
   field: 'stockLocation',
   label: 'Location',
   render: (location, item) => {
      return <Box>{item?.stockLocation}{item?.stockLocationBag && <Box>{item?.stockLocationBag}</Box>}</Box>
   }
}

const ColSortorder: ItemColumn = {
   id: 'sortorder',
   field: 'sortorder',
   label: 'Sort#'
}

const ColVendor: ItemColumn = {
   id: 'vendor',
   field: 'vendor',
   label: 'Vendor'
}

const ColOriginalEan: ItemColumn = {
   id: 'originalEan',
   label: 'EAN',
   field: 'originalEan',
   render: (ean: string) => {
      if (ean) {
         let valid = Barcoder.validate(ean);
         return <div style={{color: !valid ? 'RED' : 'inherit'}} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
         }}>{ean}</div>
      } else {
         return null
      }
   }
}

const ColNote: ItemColumn = {
   id: 'note',
   label: 'Other',
   field: 'note',
   render: (ean: string, row) => {
      return <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: "wrap"}}>
         {row?.legacyGroup && <Chip size={"small"} color={"warning"} label={'Masterdata needs update'}/>}
         {row?.test && <Chip size={"small"} color={"primary"} label={'Test item'}/>}
         {row?.note && <Chip size={"small"} label={'note: ' + row.note}/>}
         {row?.options?.map(option => (
            <Chip size={"small"} label={option?.key + ': ' + option?.value}/>
         ))}
      </Box>
   }
}

const ColReviewStatus: ItemColumn = {
   id: 'reviewStatus',
   label: 'Review status',
   field: '_id',
   render: (id: string) => {
      return <BrandItemMasterDataStatus brandItemId={id}/>
   }
}


const ColTakeAction: ItemColumn = {
   id: 'takeAction',
   label: 'Take action',
   field: '_id',
   render: (id: string, row?: FlexBrandItemFragment) => {
      return <BrandItemTakeActionColumn item={row}/>
   }
}


const ColReviewField = (field: string, title: string): ItemColumn => ({
   id: field,
   label: title,
   field: field,
   render: (value, row) => {
      if (value) {
         return value
      } else {
         return <Chip label={"Missing"}/>
      }
   }
})

const ColReviewLocalizedField = (id: string, field: string, title: string): ItemColumn => ({
   id: id,
   label: title,
   field: field,
   render: (value: LocalizationEntry[], row, context) => {
      let val = value.find(val => val.locale === context.locale);
      if (!val) {
         val = value?.[0]
      }
      return val?.value || <Chip label={"Missing"}/>
   }
})

const ColDecoratorField = (decorator: ItemColumn): ItemColumn => ({
   id: decorator.id,
   label: decorator.label,
   field: decorator.field,
   render: (value, row, context) => {
      if (decorator.render) {
         const myVal = decorator.render(value, row, context)
         if (myVal) return myVal
      }
      if (value) {
         return value
      } else {
         return <Chip label={"Missing"}/>
      }
   }
})

export type BrandItemColumnsProps = {
   id?: boolean
   brand?: boolean
   locationTs?: boolean
}

export const getBrandItemColumns = ({
                                       id,
                                       brand,
                                       locationTs
                                    }: BrandItemColumnsProps): FlexListColumn<FlexBrandItemFragment>[] => {
   let columns: FlexListColumn<FlexBrandItemFragment>[] = [];

   if (id) {
      columns.push({...ColID, width: 5});
   }
   if (brand) {
      columns.push({...ColBrand, width: 10})
   }
   columns.push(...[
      {...ColImage, width: 10},
      {...ColImageExtended, width: 30},
      {...ColReviewLocalizedField('title', 'nameLocalized', 'Product'), width: 25},
      {...ColReviewField('itemSku', 'SKU'), width: 15},
      {...ColDecoratorField(ColBeforePrice), width: 10},
      {...ColDecoratorField(ColOriginalEan), width: 15},
      {...ColReviewLocalizedField('size', 'sizeLocalized', 'Size'), width: 10},
      {...ColReviewLocalizedField('color', 'colorLocalized', 'Color'), width: 10},
      {...ColShops, width: 20},
      {...ColSortorder, width: 4},
      {...ColStockLocation, width: 12},
      {...ColVendor, width: 10},
      {...ColComment, width: 25},
      {...ColCommentApproved, width: 6},
      {...ColCommentTranslated, width: 6},
      {...ColReviewStatus, width: 40},
      {...ColTakeAction, width: 40}
   ]);

   if (locationTs) {
      columns.push({...ColLocationTS, width: 15})
   }
   columns.push({...ColNote, width: 10})
   columns.push(...[
      {...ColStatus, width: 10}
   ]);
   return columns
}

const AlertLabel = styled(Alert)(({theme}) => ({
   padding: '0'
}));
