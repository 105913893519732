import writeXlsxFile from 'write-excel-file'
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {useEffect, useState} from "react";
import {
   OrderExportFragment,
   OrderExportLineFragment,
   useExportOrderConsumerLazyQuery,
   useExportOrdersQuery
} from "../../../Queries";

type ExportLine = {
   items: OrderExportLineFragment[]
   order: OrderExportFragment

   ordersBefore: number
   ordersAfter: number
   tradeinsBefore: number
}

const numberFormat = "#,##0.00"

export const ExportToExcel = async (language: string, currency: string, data: ExportLine[]) => {
   // @ts-ignore
   const schema: any = [{
      column: "ORDERNAME",
      type: String,
      value: (object: ExportLine) => object.order.orderName || ''
   }, {
      column: "ORDERDATE",
      type: Date,
      format: "YYYY-MM-DD",
      value: (object: ExportLine) => new Date(object.order.orderDate)
   }, {
      column: "CONSUMERMAIL",
      type: String,
      value: (object: ExportLine) => object.order.consumerEmail || 'Unknown@mail',
      width: 20
   }, {
      column: "ITEMS",
      type: Number,
      value: (object: ExportLine) => object.items.length,
      format: numberFormat,
      width: 7
   }, {
      column: "RESALETOTAL",
      type: Number,
      value: (object: ExportLine) => object.items.reduce((acc, item) => acc + (item?.salesPricePreTax || 0), 0),
      format: numberFormat,
      width: 7
   }, {
      column: "RESALERETURN",
      type: Number,
      value: (object: ExportLine) => object.items
         .filter(item => item.returned)
         .reduce((acc, item) => acc + (item?.salesPricePreTax || 0), 0),
      format: numberFormat,
      width: 7
   }, {
      column: "ORDERSBEFORE",
      type: Number,
      value: (object: ExportLine) => object.ordersBefore || 0,
      format: numberFormat,
      width: 13.7
   }, {
      column: "ORDERSAFTER",
      type: Number,
      value: (object: ExportLine) => object.ordersAfter || 0,
      format: numberFormat,
      width: 12.4
   }, {
      column: "TRADEINSBEFORE",
      type: Number,
      value: (object: ExportLine) => object.tradeinsBefore || 0,
      format: numberFormat,
      width: 15.4
   }];

   await writeXlsxFile(data, {
      schema,
      fileName: 'orders-' + Date.now() + '.xlsx'
   })
}


type BrandItemExportProps = {
   language: string
   currency: string
   ids: string[]
}

export const OrdersExport = ({language, currency, ids}: BrandItemExportProps) => {
   const [open, setOpen] = useState<boolean>(true);
   const {data} = useExportOrdersQuery({
      variables: {
         orderIds: ids,
         currency: currency,
      }
   });
   const [fetchConsumerStats] = useExportOrderConsumerLazyQuery()

   useEffect(() => {
      if (data?.OrderPagination) {
         setOpen(true);
         (async function () {
            const exportLines: ExportLine[] = []
            const orders = ((data.OrderPagination?.items || []) as OrderExportFragment[])
            for (const order of orders) {
               const items = ((order.lines || []) as OrderExportLineFragment[])

               const line: ExportLine = {
                  order: order,
                  items: [],
                  ordersBefore: 0,
                  ordersAfter: 0,
                  tradeinsBefore: 0
               }

               if (order.consumerEmail) {
                  const {data: stats} = await fetchConsumerStats({
                     variables: {
                        shopId: order.shopId,
                        dateTime: order.orderDate,
                        mail: order.consumerEmail,
                        now: new Date()
                     }
                  });

                  if (stats) {
                     for (const consumer of stats.ConsumerByIdentifier || []) {
                        line.ordersBefore += consumer?.statisticsBefore?.orders || 0
                        line.tradeinsBefore += consumer?.statisticsBefore?.tradeIns || 0
                        line.ordersAfter += consumer?.statisticsAfter?.orders || 0
                     }
                     line.ordersAfter -= (line.ordersBefore + 1)
                  }
               }

               for (const item of items) {
                  line.items.push(item)
               }
               exportLines.push(line);
            }
            await ExportToExcel(language, currency, exportLines);
            setOpen(false);
         })();
      }
   }, [currency, data, fetchConsumerStats, ids, language])

   return <Dialog open={open}>
      <DialogContent>
         <CircularProgress size={100}/>
         Preparing data, please wait...
      </DialogContent>
   </Dialog>
}
