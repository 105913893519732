import React, { useState, useEffect } from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   TextField,
   Button
} from "@mui/material";

interface TradeInCommentEditorProps {
   open: boolean;
   onClose: () => void;
   onSave: (comment: string) => Promise<void>;
   initialComment: string;
}

export const TradeInCommentEditor: React.FC<TradeInCommentEditorProps> = ({
                                                                             open,
                                                                             onClose,
                                                                             onSave,
                                                                             initialComment
                                                                          }) => {
   const [commentText, setCommentText] = useState<string>('');
   const [isSaving, setIsSaving] = useState<boolean>(false);

   useEffect(() => {
      // Update the comment text when initialComment changes or dialog opens
      if (open) {
         setCommentText(initialComment || '');
      }
   }, [initialComment, open]);

   const handleSave = async () => {
      try {
         setIsSaving(true);
         await onSave(commentText);
         onClose();
      } catch (error) {
         console.error('Error saving comment:', error);
      } finally {
         setIsSaving(false);
      }
   };

   return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
         <DialogTitle>Edit Comment</DialogTitle>
         <DialogContent>
            <TextField
               autoFocus
               margin="dense"
               id="comment"
               label="Comment"
               type="text"
               fullWidth
               multiline
               rows={4}
               value={commentText}
               onChange={(e) => setCommentText(e.target.value)}
               disabled={isSaving}
            />
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} disabled={isSaving}>
               Cancel
            </Button>
            <Button
               onClick={handleSave}
               color="primary"
               disabled={isSaving}
            >
               Save
            </Button>
         </DialogActions>
      </Dialog>
   );
};