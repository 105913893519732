import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {TableVirtuoso} from "react-virtuoso";
import makeStyles from "@mui/styles/makeStyles";
import {FlexHeader, FlexHeaderData} from "./FlexHeader";
import {FlexTabs} from "./FlexTab";
import {FlexCommand, FlexFilterValue, FlexListColumn, FlexListProps, FlexTab} from "./FlexTypes";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import {
   Box,
   Button,
   CircularProgress,
   Dialog,
   DialogContent,
   IconButton,
   LinearProgress,
   ListItemIcon,
   ListItemText,
   Menu,
   MenuItem
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {PubSub} from "../PubSub";
import {atom, useAtom, useAtomValue} from 'jotai';
import {atomFamily} from 'jotai/utils';
import {buildGraphQLFilter} from "./FlexUtility";
import {useSearchParams} from 'react-router-dom';
import {applyURLParamsToFlexList, getFlexListURLParams, updateSearchParams} from "./FlexUrlSerializer";

interface FlexData {
   data: any[];
   total: number;
   loading: boolean;
   pages: any[][] | null;
}

interface FlexSelection {
   selected: string[];
   selectAll: number | undefined;
}

interface FlexSettings {
   search: string;
   filters: FlexFilterValue[];
   sort: string;
   columns: FlexListColumn<any>[];
   columnsVisible?: string[];
}

function createCleanableAtomFamily<T extends object>(defaultValue: T) {
   return atomFamily((globalId: string) => {
      const baseAtom = atom<T>(defaultValue);

      baseAtom.onMount = (setAtom) => {
         setAtom((prev) => prev);
         return () => {
            setAtom(defaultValue);
         };
      };

      return baseAtom;
   });
}

// Recreate atoms with cleanup
export const flexDataAtom = createCleanableAtomFamily<FlexData>({
   data: [],
   total: 0,
   loading: true,
   pages: null
});

export const flexSelectionAtom = createCleanableAtomFamily<FlexSelection>({
   selected: [],
   selectAll: undefined
});

export const flexSettingsAtom = createCleanableAtomFamily<FlexSettings>({
   search: '',
   filters: [],
   sort: '',
   columns: []
});

export const flexListColumnsAtom = atomFamily((globalId: string) => {
   const baseAtom = flexSettingsAtom(globalId);

   return atom((get) => {
      const listSettings = get(baseAtom);
      const columns = listSettings?.columns?.filter(column => {
         if (listSettings.columnsVisible) {
            return listSettings.columnsVisible.includes(column.id);
         }
         return true;
      }) || [];

      const result: { column: FlexListColumn<any>; width: number; }[] = [];
      const totalWidth = columns.reduce((a, b) => a + (b.width || 1), 0) || 0;

      if (totalWidth) {
         for (const column of columns) {
            const columnWidth = Math.round((column.width || 1) * 100 / totalWidth);
            result.push({
               column: column,
               width: columnWidth
            });
         }
      }
      return result;
   });
});

export const FlexList = <T extends object>(
   {
      globalId,
      columns,
      itemsPerPage,
      onRequestData,
      options,
      filters,
      sorts,
      tabs: origTabs,
      context,
      selections,
      commands,
      onTabChange,
      onSelectRow,
      initialTab
   }: FlexListProps<T>) => {
   type AnchorEL = {
      element: HTMLElement | null
      command: FlexCommand<any>
   }
   const [anchorEl, setAnchorEl] = React.useState<null | AnchorEL>(null);
   const [loadingMore, setLoadingMore] = useState<boolean>(false);
   const [tabs, setTabs] = useState<FlexTab[]>(origTabs || []);

   const [searchParams, setSearchParams] = useSearchParams();
   const urlParams = applyURLParamsToFlexList(searchParams);

   const [activeTab, setActiveTab] = useState<string>(urlParams.tabId || initialTab || '');

   const [headerData, setHeaderData] = useState<FlexHeaderData>({
      search: urlParams.search,
      sort: sorts?.find(sort => sort.value === urlParams.sort)?.id || '',
      filterAnd: urlParams.filterAnd || {},
      filterOr: urlParams.filterOr || {}
   });

   const [initialized, setInitialized] = useState<boolean>(false);

   const [executing, setExecuting] = useState<string>('');

   const [flexData, setFlexData] = useAtom(flexDataAtom(globalId));
   const [selection, setSelection] = useAtom(flexSelectionAtom(globalId));

   const [listState, setListState] = useAtom(flexSettingsAtom(globalId));
   const visibleColumns = useAtomValue(flexListColumnsAtom(globalId));

   const classes = useStyles();

   const loadData = useCallback(async () => {
      if (flexData.loading || loadingMore || (options?.maxRows && flexData.data.length >= options.maxRows)) return;
      if (flexData.data.length >= flexData.total) return;

      const nextPage = Math.floor(flexData.data.length / itemsPerPage) + 1;

      try {
         setLoadingMore(true); // Set loading state for pagination

         const pageData = await onRequestData(
            listState.search,
            listState.sort,
            nextPage,
            buildGraphQLFilter(filters, listState.filters)
         );

         setFlexData(prev => {
            const newPages = [...prev.pages!];
            newPages[nextPage] = pageData.data;
            return {
               ...prev,
               pages: newPages,
               total: pageData.totalCount,
               data: newPages.flat().filter(Boolean),
            };
         });
      } catch (error) {
         console.error('Error loading more data:', error);
      } finally {
         setLoadingMore(false); // Reset loading state
      }
      // eslint-disable-next-line
   }, [flexData.pages, flexData.total, listState, globalId, options?.maxRows, loadingMore]);

   useEffect(() => {
      // Only reset data if initialized
      if (!initialized) return;

      // First clear related atoms
      setFlexData({
         data: [],
         total: 0,
         loading: true,
         pages: null
      });
      setSelection({
         selected: [],
         selectAll: undefined
      });
      // eslint-disable-next-line
   }, [listState, globalId]);

// Update your useEffect that loads data:
   useEffect(() => {
      if (!flexData.loading || !initialized) return;

      const fetchData = async () => {
         try {
            const pageData = await onRequestData(
               listState.search,
               listState.sort,
               1,
               buildGraphQLFilter(filters, listState.filters)
            );

            setFlexData({
               pages: [pageData.data],
               total: pageData.totalCount,
               data: pageData.data,
               loading: false
            });

            // Update tab counts if needed
            if (tabs.length > 0) {
               const updatedTabs = [...tabs];

               // Logic to update tab counts would go here
               // This depends on how you want to implement this feature

               // Example: calculate counts for each tab
               for (const tab of updatedTabs) {
                  if (tab.showCount) {
                     const tabCount = await onRequestData(
                        listState.search,
                        listState.sort,
                        1,
                        buildGraphQLFilter(filters, tab.filters),
                        1
                     )
                     tab.count = tabCount.totalCount
                  }
               }
               setTabs(updatedTabs);
            }

            setInitialized(true);
         } catch (error) {
            console.error('Error loading data:', error);
            setFlexData(prev => ({...prev, loading: false}));
         }
      };

      fetchData();

      // eslint-disable-next-line
   }, [listState, globalId, initialized]);

   useEffect(() => {
      if (selection.selectAll !== undefined) {
         if (flexData.data.length === flexData.total) {
            // All data is loaded, select all rows
            const newSelected = flexData.data.map((row) => row?.[idField]);
            setSelection({
               selected: newSelected,
               selectAll: undefined, // Reset select all state
            });
         } else {
            // Load more data and continue the select all process
            const handle = setTimeout(async () => {
               await loadData();
               if (flexData.data.length < flexData.total) {
                  setSelection({
                     ...selection,
                     selectAll: (selection.selectAll || 0) + 1, // Increment selectAll counter
                  });
               } else {
                  // All data is loaded, select all rows
                  const newSelected = flexData.data.map((row) => row?.[idField]);
                  setSelection({
                     selected: newSelected,
                     selectAll: undefined, // Reset select all state
                  });
               }
            }, 100);
            return () => clearTimeout(handle);
         }
      }
      // eslint-disable-next-line
   }, [selection.selectAll, flexData, options?.idField, loadData, setSelection]);

   // Modify the initialization effect
   useEffect(() => {
      if (!initialized && columns) {
         let selectedTab;

         // First try to get tab from URL
         if (urlParams.tabId && tabs) {
            selectedTab = tabs.find(tab => tab.id === urlParams.tabId);
         }
         // Then fallback to initialTab prop
         else if (initialTab && tabs) {
            selectedTab = tabs.find(tab => tab.id === initialTab);
         }
         // Then fallback to first tab with no filters
         if (!selectedTab && tabs) {
            selectedTab = tabs.find(tab => tab.filters.length === 0);
         }

         // Get filters from URL or selected tab
         const effectiveFilters = urlParams.filters.length > 0
            ? urlParams.filters
            : (selectedTab?.filters || []);

         if (selectedTab) {
            setActiveTab(selectedTab.id);
            setListState({
               ...listState,
               columns: columns,
               columnsVisible: selectedTab.columns,
               filters: effectiveFilters,
               search: urlParams.search,
               sort: urlParams.sort || ''
            });
         } else {
            setListState({
               ...listState,
               columns: columns,
               search: urlParams.search,
               filters: effectiveFilters,
               sort: urlParams.sort || ''
            });
         }

         if (selectedTab) {
            onTabChange?.(selectedTab.id);
         }
         setInitialized(true);
      }
      // eslint-disable-next-line
   }, [columns, initialized]);

   // Add effect to track when listState is properly set
   useEffect(() => {
      if (listState.columns?.length > 0) {
         setInitialized(true);
      }
   }, [listState.columns]);

   useEffect(() => {
      return PubSub.on('FlexList-DataChange', (updatedData: any) => {
         const id = updatedData?.[idField];
         if (!id) return;

         setFlexData(prev => {
            const findData = prev.data.find((row) => row[idField] === id);
            if (!findData) return prev;

            const newData = prev.data.map((row) =>
               row[idField] === id ? {...row, ...updatedData} : row
            );

            return {
               ...prev,
               data: newData
            };
         });
      });
      // eslint-disable-next-line
   }, [flexData]);

   // Initialize headerData from listState
   useEffect(() => {
      if (!initialized) return;

      const effectiveFilterOr: any = {};
      const effectiveFilterAnd: any = {};

      // Process filters from listState
      for (const filter of listState.filters || []) {
         if (filter.requireAll) {
            effectiveFilterAnd[filter.filterId] = filter.values;
         } else {
            effectiveFilterOr[filter.filterId] = filter.values;
         }
      }

      // Set the header data
      setHeaderData({
         search: listState.search || '',
         filterAnd: effectiveFilterAnd,
         filterOr: effectiveFilterOr,
         sort: sorts?.find(sort => sort.value === listState.sort)?.id || '',
      });
   }, [listState, sorts, initialized]);

// Modify the onHeaderChanged function
   const onHeaderChanged = (newHeaderData: FlexHeaderData) => {
      // Get the sort value based on the ID passed from FlexHeader
      const sortValue = sorts?.find(sort => sort.id === newHeaderData.sort)?.value;

      const resultFilters: FlexFilterValue[] = [];

      for (const key of Object.keys(newHeaderData.filterAnd)) {
         let selected = newHeaderData.filterAnd[key];
         if (selected?.length > 0) {
            resultFilters.push({
               filterId: key,
               values: selected,
               requireAll: true
            });
         }
      }

      for (const key of Object.keys(newHeaderData.filterOr)) {
         let selected = newHeaderData.filterOr[key];
         if (selected?.length > 0) {
            resultFilters.push({
               filterId: key,
               values: selected,
               requireAll: false
            });
         }
      }

      setHeaderData(newHeaderData);

      // Update listState with the correct sort value
      setListState({
         ...listState,
         search: newHeaderData.search,
         sort: sortValue,
         filters: resultFilters,
      });

      // Update URL with all parameters
      const newParams = updateSearchParams(
         searchParams,
         getFlexListURLParams(
            newHeaderData.search,
            activeTab,
            resultFilters,
            sortValue
         )
      );

      setSearchParams(newParams);

      // Trigger data reload
      setFlexData(prev => ({
         ...prev,
         loading: true,
         pages: null
      }));
   };

   const onSelectAllClick = async (selectAll: boolean) => {
      if (selectAll) {
         setSelection({
            ...selection,
            selectAll: 1 // Start the select all process
         });
      } else {
         setSelection({
            ...selection,
            selected: [],
            selectAll: undefined // Reset select all state
         });
      }
   };

   const handleTabChange = (newTabId: string) => {
      setActiveTab(newTabId);
      const newTab = tabs?.find(tab => tab.id === newTabId);

      if (newTab) {
         // Update list state
         setListState(prev => ({
            ...prev,
            filters: newTab.filters || [],
            columnsVisible: newTab.columns
         }));

         // Update URL
         const newParams = updateSearchParams(
            searchParams,
            getFlexListURLParams(
               listState.search,
               newTabId,
               newTab.filters || [],
               listState.sort
            )
         );

         setSearchParams(newParams);

         // Trigger data reload
         setFlexData(prev => ({
            ...prev,
            loading: true,
            pages: null
         }));

         onTabChange?.(newTabId);
      }
   };

   const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selection.selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selection.selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selection.selected.slice(1));
      } else if (selectedIndex === selection.selected.length - 1) {
         newSelected = newSelected.concat(selection.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selection.selected.slice(0, selectedIndex),
            selection.selected.slice(selectedIndex + 1),
         );
      }

      setSelection({
         ...selection,
         selected: newSelected
      });
   };

   const onExecute = async (cmd: FlexCommand<T>) => {
      setExecuting(cmd.id);

      if (cmd.execute) {
         const selectedRows = flexData.data.filter(row =>
            selection.selected.includes(row[idField])
         );
         await cmd.execute(selectedRows);
      }

      setFlexData(prev => ({
         ...prev,
         pages: null,
         loading: true
      }));

      await loadData();
      PubSub.emit('FlexList-SearchChanged-' + globalId, listState);
      setExecuting('');
   };

   const resetFlexList = () => {
      // Create empty header data
      const defaultHeaderData: FlexHeaderData = {
         search: '',
         filterAnd: {},
         filterOr: {},
         sort: ''
      };
      // Update header data
      setHeaderData(defaultHeaderData);

      // Update the list state
      setListState({
         ...listState,
         search: '',
         sort: '',
      });

      // Update URL by removing all query parameters
      const newParams = new URLSearchParams();
      setSearchParams(newParams);

      setInitialized(false);

      // Trigger data reload
      setFlexData(prev => ({
         ...prev,
         loading: true,
         pages: null
      }));
   };

   const isSelected = (id: string) => selection.selected.includes(id);
   if (!listState.columns) {
      return <LinearProgress/>
   }

   const idField = options?.idField || '_id'

   const commandOpen = Boolean(anchorEl);

   const commandHandleClick = (cmd: FlexCommand<T>, event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl({
         command: cmd,
         element: event.currentTarget
      });
   };
   const commandHandleClose = () => {
      setAnchorEl(null);
   };

   const isAllSelected = selection.selected.length === flexData.total && flexData.total > 0;
   const isIndeterminate = selection.selected.length > 0 && selection.selected.length < flexData.total;

   if (!initialized) {
      return <Box></Box>
   }

   return <div className={classes.flexListContainer}>
      <Dialog open={selection.selectAll !== undefined} maxWidth={"xs"}>
         <DialogContent style={{textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
            <div><CircularProgress/></div>
            <div>Selecting rows, please wait...</div>
         </DialogContent>
      </Dialog>

      {!options?.hideHeader && tabs && <div className={classes.flexTab}>
         <FlexTabs
            tabs={tabs.map(tab => ({
               ...tab,
               count: tab.count
            }))}
            tabId={activeTab}
            onTabChange={handleTabChange}
         />
      </div>}
      {!options?.hideHeader && (
         <div className={classes.flexHeader}>
            <FlexHeader
               count={flexData.total}
               filters={filters}
               sorts={sorts}
               searchLabel={options?.searchLabel}
               style={{display: selection.selected.length > 0 ? 'none' : 'block'}}
               headerData={headerData}
               onHeaderChanged={onHeaderChanged}
               onReset={resetFlexList}
            />
            <div className={classes.flexCommand} style={{display: selection.selected.length > 0 ? 'flex' : 'none'}}>
               <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                  {selection.selected.length} selected
                  <IconButton
                     size="small"
                     onClick={() => setSelection(prev => ({...prev, selected: []}))}>
                     <ClearIcon fontSize="inherit"/>
                  </IconButton>
               </Typography>
               <div className={classes.commands}>
                  {commands?.map(cmd => {
                     const onClick = async () => await onExecute(cmd);
                     return (
                        <Box key={'command_' + cmd.id}>
                           <Tooltip title={cmd.title} key={'cmd_' + cmd.id}>
                              <Button
                                 aria-label={cmd.title}
                                 onClick={cmd.children ? (e) => commandHandleClick(cmd, e) : onClick}
                                 size="small"
                                 startIcon={
                                    cmd.children ? undefined :
                                       executing === cmd.id ? <CircularProgress size={18}/> :
                                          <cmd.icon/>
                                 }
                                 endIcon={cmd.children ? <ArrowDropDownIcon/> : undefined}
                              >
                                 {cmd.title}
                              </Button>
                           </Tooltip>
                        </Box>
                     );
                  })}
               </div>
            </div>
         </div>
      )}
      {anchorEl && <Menu id={"menu_" + anchorEl.command!.id} open={commandOpen} anchorEl={anchorEl.element}
                         onClose={commandHandleClose}>
         {anchorEl.command!.children!.map(childCmd => {
            return <MenuItem key={'cmd_' + childCmd.id}
                             onClick={async () => await onExecute(childCmd)}>
               <ListItemIcon>
                  {executing === childCmd.id ? <CircularProgress size={18}/> : <childCmd.icon/>}
               </ListItemIcon>
               <ListItemText>
                  {childCmd.title}
               </ListItemText>
            </MenuItem>
         })}
      </Menu>}

      <div className={classes.flexContent}>
         <TableVirtuoso
            data={flexData.data}
            endReached={loadData}
            fixedItemHeight={options?.itemHeight}
            useWindowScroll={!options?.height}
            style={{width: '100%', height: options?.height}}
            className={classes.table}
            increaseViewportBy={options?.height || 50}
            overscan={60}
            fixedHeaderContent={() => {
               return <tr>
                  {selections && <td className={classes.headerCell}
                                     width={'40px'}
                                     key={'select_all_column'}>
                     <Checkbox
                        indeterminate={isIndeterminate}
                        checked={isAllSelected}
                        onChange={async (e) => await onSelectAllClick(e.target.checked)}
                        inputProps={{'aria-label': 'select all data'}}
                     />
                  </td>}
                  {visibleColumns.map((column, index) => {
                     return <td
                        className={classes.headerCell}
                        width={column.width + '%'}
                        key={'header_' + column.column.id + '_' + index}>
                        {column.column.label}
                     </td>
                  })}</tr>
            }}
            itemContent={(index: number, row: any) => {
               if (!row) {
                  return <></>
               }
               let id = row[idField];
               if (!row) {
                  return <></>
               }
               const isItemSelected = isSelected(id);
               const labelId = `enhanced-table-checkbox-${index}`;
               return <>
                  {selections && <td className={classes.dataCell}
                                     width={'40px'}
                                     key={'select_all_column'}>
                     <Checkbox
                        onClick={(event) => {
                           event.preventDefault();
                           event.stopPropagation();
                           handleClick(event, id)
                        }}
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                     />
                  </td>}

                  {visibleColumns.map((visibleColumn, colindex) => {
                     const column = visibleColumn.column;
                     const value = getCellValue(column, row);
                     let innerComponent;
                     if (column.render !== undefined) {
                        innerComponent = <>{column.render(value, row, context)}</>
                     } else {
                        innerComponent = <>{value}</>
                     }
                     let title: string | undefined;
                     if (typeof value === 'string') {
                        title = String(value);
                     }

                     return <td
                        onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           onSelectRow && onSelectRow(row, e.ctrlKey)
                        }}
                        className={classes.dataCell}
                        width={visibleColumn.width + '%'}
                        title={title}
                        key={"r_" + id + "_" + column.id + "_" + colindex}
                        style={{height: options?.itemHeight, cursor: onSelectRow ? 'pointer' : undefined}}>
                        {column.ellipsis ?
                           <div style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              marginRight: '15px'
                           }}>
                              {innerComponent}
                           </div>
                           : <>
                              {innerComponent}
                           </>
                        }
                     </td>
                  })}</>
            }}
            fixedFooterContent={() => {
               return <tr>
                  <td colSpan={visibleColumns.length + 1}>
                     {(flexData.loading || loadingMore) && <LinearProgress/>}
                  </td>
               </tr>
            }}
         >
         </TableVirtuoso>
      </div>
   </div>
}


/**
 * Responsible for getting cell value given a column and a row.
 * @param column The column
 * @param row The row
 */
const getCellValue = (column: FlexListColumn<any>, row: any) => {
   if (column.get !== undefined) {
      return column.get(row)
   } else if (column.field !== undefined) {
      return row[column.field];
   } else {
      throw new Error('Column "' + column.label + '" must have either get or field');
   }
}

const useStyles = makeStyles({
   flexListContainer: {
      display: 'flex',
      flexDirection: 'column'
   },
   flexTab: {
      marginBottom: '3px',
   },
   flexHeader: {},
   flexContent: {
      marginTop: '4px',
      "& tr:hover": {
         transition: 'all .2s ease',
         backgroundColor: '#F4F4F4'
      }
   },
   table: {
      "& table": {
         tableLayout: "fixed",
         width: '100%',
         overflow: "hidden",
         wordWrap: "break-word"
      }
   },
   commands: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
   },
   headerCell: {
      fontWeight: 600,
      backgroundColor: '#F4F4F4'
   },
   dataCell: {
      borderTop: '1px solid #e0e0e0'
   },
   title: {},
   flexCommand: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#f8f8f8',
      paddingTop: '14px',
      paddingBottom: '14px'
   }
});
