import * as React from "react";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import {DataColumn, DataTable} from "../../../components/DataTable";
import Page from "../../../layout/Page";
import PageHeader from "../../../layout/PageHeader";
import PageContent from "../../../layout/PageContent";
import {DateFormat} from "../../../utility/DateFormat";
import {
   useVouchersPageMergeMutation,
   useVouchersPageVoucherDeleteMutation,
   useVouchersPageVouchersQuery,
   Voucher,
   VouchersPageVouchersDocument
} from "../../../Queries";
import {VoucherPagePath} from "./VoucherPage";
import {CallMerge, FileDownload} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {Dialogs} from "../../../DialogProvider";
import {SavingUI} from "../../../SavingProvider";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../../atoms/CurrentUser";
import {VouchersExport} from "./VouchersExport";
import {ParamBrandHouse} from "../../../Parameters";

export const VouchersPage: FC<any> = () => {
   const [merging, setMerging] = React.useState<Voucher[]>([]);
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const [exportIds, setExportIds] = React.useState<string[]>([])

   const {brandHouseId} = useParams<ParamBrandHouse>();
   const {data} = useVouchersPageVouchersQuery({
      variables: {
         brandHouseId: brandHouseId!
      }
   });
   const [voucherMerge] = useVouchersPageMergeMutation();
   const [voucherDelete] = useVouchersPageVoucherDeleteMutation();
   const navigate = useNavigate();

   if (!data) {
      return <LinearProgress/>
   }
   const vouchers = data.Vouchers;

   let columns: DataColumn<Voucher>[] = [{
      label: 'Date',
      get: (row) => {
         return new Date(row.createdAt!).getTime();
      },
      render: (dateStr) => {
         return DateFormat.toPresent(new Date(dateStr))
      }
   }, {
      label: 'Consumer',
      get: (order) => {
         if (order.consumer) {
            return order.consumer.firstName + ' ' + order.consumer.lastName
         } else {
            return 'Unknown';
         }
      },
      searchable: true
   }, {
      field: 'code',
      label: 'Code',
      searchable: true,
      get: (voucher) => {
         return voucher.codeAlias || voucher.code
      }
   }, {
      label: 'Value',
      get: (voucher) => {
         return Math.round(voucher.value || 0) + ' ' + voucher.currency
      },
      searchable: true
   }, {
      label: 'Remaining',
      get: (row) => {
         return Math.round(row.currentValue || 0) + ' ' + row.currency
      },
      render: (value, row) => {
         return value;
      }
   }, {
      label: 'Deliver',
      field: 'voucherStrategy',
      render: (strategy) => {
         if (strategy === 'RECEIVED') {
            return "Receive";
         } else if (strategy === 'INSTANT') {
            return "Instant"
         } else if (strategy === 'HANDIN') {
            return "Handin"
         } else {
            return "Unknown?";
         }
      }
   }, {
      label: 'Active',
      get: (row) => {
         return row._id
      },
      render: (ignore, row) => {
         if (row.deleted) {
            return <Alert severity={"error"}>{"Deleted " + DateFormat.toReadable(row.deleted)}</Alert>
         } else if (row.activated) {
            if (row.passive) {
               if ((row.currentValue || 0) !== row.value) {
                  return <Alert severity={"info"}>Used</Alert>
               } else {
                  return <Alert severity={"success"}>Active</Alert>
               }
            } else if (row.used && (row.currentValue || 0) > 0) {
               return <Alert severity={"success"}>Active (see details)</Alert>
            } else if (row.used) {
               return <Alert severity={"info"}>{"Used " + DateFormat.toReadable(row.used)}</Alert>
            } else {
               return <Alert severity={"success"}>Active</Alert>
            }
         } else {
            return <Alert severity={"warning"}>Not activated</Alert>
         }
      }
   }]

   if (isAdministrator) {
      columns.push({
         label: '',
         get: (row) => {
            return row._id
         },
         render: (ignore, row) => {
            if (row.passive) {
               return <Chip color={"secondary"} label={"GC"}/>
            } else if (row.newVoucherUsage) {
               return <Chip color={"primary"} label={"V2"}/>
            }
         },
         width: '10px'
      });
   }

   columns.push({
      get: (row) => row.consumer?.mail,
      label: 'mail',
      hidden: true,
      searchable: true
   });

   const onExecuteMerge = async (row: Voucher) => {
      await voucherMerge({
         variables: {
            shopId: row.shopId!,
            tradeInId: row.tradeInId,
            vouchers: merging.map(merge => String(merge._id))
         },
         refetchQueries: [{
            query: VouchersPageVouchersDocument,
            variables: {
               brandHouseId: brandHouseId!
            }
         }]
      });
      setMerging([])
   }

   const onHandleClick = (row: Voucher) => {
      navigate(VoucherPagePath(brandHouseId!, row.shopId!, row._id));
   }

   let commands = [{
      id: 'merge',
      icon: CallMerge,
      title: 'Merge',
      execute: async (rows: Voucher[]) => {
         setMerging(rows);
      }
   }, {
      id: 'export',
      title: 'Export to excel',
      icon: FileDownload,
      execute: async (rows: Voucher[]) => {
         setExportIds(rows.map(row => row._id) || [])
      }
   }];

   if (isAdministrator) {
      commands.push({
         id: 'delete',
         icon: DeleteIcon,
         title: 'Delete',
         execute: async (rows) => {
            Dialogs.confirm({
               title: 'Really delete vouchers?',
               subtitle: 'Its not possible to undo this operation'
            }, async () => {
               await SavingUI.process(async () => {
                  for (const row of rows) {
                     await voucherDelete({
                        variables: {
                           shopId: row.shopId!,
                           voucherId: row._id
                        }
                     });
                  }
               }, 'Deletion completed')
            })
         }
      })
   }
   return <Page>
      {merging.length > 0 && <Dialog open={merging.length > 0}>
         <DialogTitle>This will move the remaining value from selected vouchers</DialogTitle>
         <DialogContent>
            The value of the selected vouchers will be moved into one voucher.
            Please select the main voucher from the list below.
         </DialogContent>
         <DialogContent>
            {merging.map(merge => {
               return <Button onClick={() => onExecuteMerge(merge)}>{merge.code}</Button>
            })}
         </DialogContent>
         <DialogActions>
            <Button variant={"text"} onClick={() => setMerging([])}>Hide</Button>
         </DialogActions>
      </Dialog>}
      <PageHeader title={"Rewards"}/>
      <PageContent fullHeight>
         <DataTable
            idField={"_id"}
            columns={columns}
            rows={vouchers || []}
            order={"desc"}
            onSingleSelect={onHandleClick}
            selections={true}
            commands={commands}
         />
         {exportIds.length > 0 && <VouchersExport ids={exportIds}/>}

      </PageContent>
   </Page>
}