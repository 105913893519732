import * as React from "react";
import {FC, useCallback} from "react";
import Page from "../../../layout/Page";
import PageContentTabs, {PageTab} from "../../../layout/PageContentTabs";
import {ConsumerEvents} from "../../brand/ConsumerEvents";
import {useNavigate, useParams} from "react-router-dom";
import PageContent from "../../../layout/PageContent";
import {
   PlannedMail,
   SortFindManyPlannedMailInput,
   useConsumerEventsTradeInQuery,
   useConsumerEventsVoucherQuery,
   useConsumerMailResendMutation,
   useConsumerPlannedMailsLazyQuery,
   useConsumerSendAllMutation
} from "../../../Queries";
import {FlexCommand, FlexListColumn} from "../../../components/flexlist/FlexTypes";
import {FlexList} from "../../../components/flexlist/FlexList";
import {Alert, Box} from "@mui/material";
import {Mail} from "@mui/icons-material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import dayjs from "dayjs";
import {TradeInPagePath} from "./TradeInPage";
import Button from "@mui/material/Button";
import {Dialogs} from "../../../DialogProvider";
import {SavingUI} from "../../../SavingProvider";
import {VoucherPagePath} from "../../brand/vouchers/VoucherPage";

export const ConsumerEventsPage: FC<any> = () => {
   const {shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const [sendAll] = useConsumerSendAllMutation()

   const tabs: PageTab[] = [{
      label: "Shop events"
   }, {
      label: "Planned mails"
   }]

   const onSendVoucherReminders = async () => {
      Dialogs.confirm({
         title: 'Are you sure?',
         subtitle: 'This will send reminder mails to all voucher owners that have unused vouchers.'
      }, async () => {
         await SavingUI.process(async () => {
            await sendAll({
               variables: {
                  shopId
               }
            })
         }, 'Sending voucher reminder mails')
      })
   }

   return <Page>
      <PageContentTabs onBack tabs={tabs} render={(tab) => {
         return <PageContent commands={tab === "Planned mails" && <Button onClick={onSendVoucherReminders}>
            Send voucher reminder mails</Button>}>
            {tab === "Shop events" && <ConsumerEvents/>}
            {tab === "Planned mails" && <PlannedMails/>}
         </PageContent>
      }}/>
   </Page>
}


const PlannedMails = () => {
   const {brandHouseId, shopId} = useParams<{ brandHouseId: string, shopId: string }>();
   const [fetchMore] = useConsumerPlannedMailsLazyQuery();
   const [resend] = useConsumerMailResendMutation()
   const navigate = useNavigate();

   const columns: FlexListColumn<any>[] = [{
      id: 'objectType',
      field: 'sendAt',
      label: 'Planned for',
      render: (data, row) => {
         return dayjs(data).format('DD-MM-YYYY HH:mm')
      }
   }, {
      id: 'name',
      field: '_id',
      label: 'Receiver',
      render: (data, row) => {
         if (row.objectType === 'tradein') {
            return <TradeInChip tradeIn={row.objectId}/>
         } else {
            return <VoucherChip voucherId={row.objectId}/>
         }
      }
   }, {
      id: 'mailType',
      field: 'mailType',
      label: 'Mail',
      render: (data, row) => {
         return <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px'
         }}>
            {row.objectType === 'voucher' && <CardGiftcardIcon/>}
            {row.objectType === 'tradein' && <DoubleArrowIcon/>}
            {row.objectType === 'voucher' && <>Voucher Usage Reminder</>}
            {row.objectType === 'tradein' && <>TradeIn Package Reminder</>}
         </Box>
      }
   }, {
      id: 'objectType',
      field: 'sent',
      label: 'Status',
      render: (data, row) => {
         if (row.sent) {
            return <Alert severity={"success"} title={dayjs(data).format('DD-MM-YYYY HH:mm:ss')}>Sent</Alert>
         } else {
            return <></>
         }
      }
   }]

   const onRequestData = useCallback(async (search: string | undefined, sort: string | undefined, page: number, filter: any, maxItems?: number) => {
      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyPlannedMailInput) || SortFindManyPlannedMailInput.SHOPID__SENDAT_DESC,
            perPage: maxItems || 20,
            search: search,
            filter: {
               shopId: shopId,
               ...filter
            }
         }
      });

      return {
         data: data?.PlannedMailsPagination?.items || [],
         totalCount: data?.PlannedMailsPagination?.count || 0
      }
   }, [fetchMore, shopId]);

   const commands: FlexCommand<Partial<PlannedMail>>[] = [{
      id: 'resend',
      icon: Mail,
      title: 'Resend',
      execute: async (rows) => {
         for (const row of rows) {
            await resend({
               variables: {
                  mailId: row._id
               }
            })
         }
      }
   }]

   const onSelectRow = (row: any) => {
      if (row.objectType === 'tradein') {
         navigate(TradeInPagePath(brandHouseId, shopId, row.objectId))
      } else {
         navigate(VoucherPagePath(brandHouseId, shopId, row.objectId))
      }
   }

   return <FlexList
      globalId={"plannedMails"}
      selections={true}
      columns={columns}
      onRequestData={onRequestData}
      itemsPerPage={20}
      onSelectRow={onSelectRow}
      commands={commands}
   />
}


const TradeInChip = ({tradeIn}: { tradeIn: string }) => {
   const {data} = useConsumerEventsTradeInQuery({
      variables: {
         tradeInID: tradeIn
      }
   })
   return <Box>{data?.TradeIn?.consumer?.name}</Box>
}

const VoucherChip = ({voucherId}: { voucherId: string }) => {
   const {data} = useConsumerEventsVoucherQuery({
      variables: {
         voucherID: voucherId
      }
   })
   return <Box>{data?.VoucherById?.consumer?.name}</Box>
}
