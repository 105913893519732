import * as React from "react";
import { FC } from "react";
import { Tab, Tabs } from "@mui/material";
import { FlexFilter, FlexTab as FlexTabType } from "./FlexTypes";
import makeStyles from "@mui/styles/makeStyles";

type FlexTabsProps = {
   tabs?: FlexTabType[];
   tabId: string; // Current active tab ID
   filters?: FlexFilter<any>[];
   onTabChange?: (tabId: string) => void;
}

/**
 * A controlled component for displaying tabs in the FlexList component
 */
export const FlexTabs: FC<FlexTabsProps> = ({
                                               tabs,
                                               tabId,
                                               onTabChange
                                            }) => {
   const handleTabChange = (_: React.SyntheticEvent, newTabId: string) => {
      if (onTabChange) {
         onTabChange(newTabId);
      }
   };

   if(!tabs?.length) {
      return <></>
   }

   return (
      <Tabs
         value={tabId}
         onChange={handleTabChange}
         style={{ marginBottom: '5px' }}
      >
         {tabs?.map(tab => (
            <Tab
               key={`flex_tab_${tab.id}`}
               label={<FlexTabLabel tab={tab} />}
               value={tab.id}
            />
         ))}
      </Tabs>
   );
};

type FlexTabLabelProps = {
   tab: FlexTabType;
}

const FlexTabLabel: FC<FlexTabLabelProps> = ({ tab }) => {
   const classes = useStyles();

   return (
      <div>
         {tab.label}
         {tab.showCount && tab.count !== undefined && tab.count > 0 && (
            <span className={classes.badge}>{tab.count}</span>
         )}
      </div>
   );
};

const useStyles = makeStyles({
   badge: {
      borderRadius: '50%',
      backgroundColor: 'rgb(255, 83, 118)',
      display: 'inline-block',
      color: 'white',
      paddingLeft: '5px',
      paddingRight: '5px',
      marginLeft: '5px'
   }
});