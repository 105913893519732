import * as React from "react";
import {useRef} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import Page from "../../layout/Page";
import PageHeader from "../../layout/PageHeader";
import PageContent from "../../layout/PageContent";
import {BrandItemsList} from "./BrandItemsList";
import {
   BrandItemStateEnum,
   EnumOrder2CarrierInputCarrier,
   EnumOrder2CarrierInputDelivery,
   EnumOrder2FulState,
   EnumOrder2OrderState,
   EnumOrder2PayState,
   FlexBrandItemFragment,
   useBrandItemDeleteMutation,
   useBrandItemImageAutoAdjustMutation,
   useBrandItemRetouchMutation,
   useBrandItemsPageChangeStateMutation,
   useBrandItemsPagePushMutation,
   useBrandItemsPageShopsQuery,
   useBrandItemsUpdateCacheMutation,
   useBrandItemUpdateGroupMutation,
   useOrderCreateMutation
} from "../../Queries";
import {BrandItemCreatePagePath, BrandItemPagePath} from "./brandItem/BrandItemPage";
import {Box, Button, Drawer, LinearProgress} from "@mui/material";
import {BrandItemDialog} from "./BrandItemDialog";
import {FlexCommand} from "../../components/flexlist/FlexTypes";
import {
   ContentPaste,
   EditAttributes,
   EditAttributesOutlined,
   FileDownload,
   ImageAspectRatioOutlined,
   Storefront
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {SavingUI} from "../../SavingProvider";
import {Dialogs} from "../../DialogProvider";
import EditIcon from "@mui/icons-material/Edit";
import {BrandItemMultiEdit} from "./BrandItemMultiEdit";
import {useRecoilValue} from "recoil";
import {CurrentUserIsAdmin} from "../../atoms/CurrentUser";
import {ImportItems, ImportItemsFromExcel, ImportRowType} from "./BrandItemImport";
import {useApolloClient} from "@apollo/client";
import {TitleText} from "../../layout/Typography";
import {BrandItemExport} from "./BrandItemExport";
import {ParamBrandHouse, ParamBrandItem} from "../../Parameters";
import useLocalStorage from "../../hooks/useLocalStorage";
import CampaignPricing from "./CampaignPricing";

export const BrandItemsPagePath = (brandHouseId: string) => {
   return "/brands/" + brandHouseId + "/stock";
}

export const BrandItemsPageWrapper = () => {
   const {brandHouseId, brandItemId} = useParams<ParamBrandHouse & ParamBrandItem>();
   const navigate = useNavigate();

   const onCloseDrawer = () => {
      navigate(BrandItemsPagePath(brandHouseId))
   }

   const drawerOpen = !!brandItemId
   return (
      <>
         <BrandItemsPage/>
         {brandItemId && (
            <Drawer anchor="right" open={drawerOpen} onClose={onCloseDrawer}
                    PaperProps={{style: {width: '70%', backgroundColor: '#f8f8f8'}}}>
               <Box sx={{padding: '20px'}}>
                  <Outlet/>
               </Box>
            </Drawer>
         )}
      </>
   );

}

export const BrandItemsPage = () => {
   const {brandHouseId} = useParams<{ brandHouseId: string }>();
   const navigate = useNavigate();
   const [editCampaign, setEditCampaign] = React.useState<boolean>(false);
   const [exportIds, setExportIds] = React.useState<string[]>([])
   const [importRows, setImportRows] = React.useState<ImportRowType[]>([]);
   const importSearchField = useRef<string>('');
   const [creating, setCreating] = React.useState<boolean>(false);
   const [editing, setEditing] = React.useState<string[]>([]);
   const {data} = useBrandItemsPageShopsQuery({
      variables: {
         brandHouseId
      }
   });
   const client = useApolloClient();
   const [pushBrandItem] = useBrandItemsPagePushMutation()
   const [changeState] = useBrandItemsPageChangeStateMutation()
   const [deleteBrandItem] = useBrandItemDeleteMutation()
   const [retouchImages] = useBrandItemRetouchMutation()
   const [updateCache] = useBrandItemsUpdateCacheMutation()
   const isAdministrator = useRecoilValue(CurrentUserIsAdmin);
   const importRef = useRef<HTMLInputElement | null>(null)
   const [useDrawer] = useLocalStorage<boolean>('c2s-stockitem-drawer', true);
   const [adjustImages] = useBrandItemImageAutoAdjustMutation()
   const [createOrder] = useOrderCreateMutation()
   const [updateGroup] = useBrandItemUpdateGroupMutation();

   const onSelectBrandItem = (brandItem: FlexBrandItemFragment, newWindow?: boolean) => {
      if (newWindow) {
         window.open(BrandItemPagePath(brandHouseId, brandItem._id, false), '_blank', '')
      } else {
         navigate(BrandItemPagePath(brandHouseId, brandItem._id, useDrawer))
      }
   }
   const onCreating = () => {
      setCreating(true);
   }
   const onCancel = () => {
      setCreating(false);
   }
   const onCreateStockItem = async (brandProductSizeId: string) => {
      navigate(BrandItemCreatePagePath(brandHouseId, brandProductSizeId))
   }

   const onCreateEmpty = () => {
      navigate(BrandItemCreatePagePath(brandHouseId))
   }

   const onCloseEditor = () => {
      setEditing([])
   }

   if (!data) {
      return <LinearProgress/>
   }

   const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files?.[0];
      if (file) {
         const importResult = await ImportItemsFromExcel(file);
         Dialogs.confirm({
            title: 'Import ' + importResult.name + '?',
            subtitle: 'File contains ' + importResult.rows.length + ' lines. Search using ' + importResult.search
         }, () => {
            importSearchField.current = importResult.search
            setImportRows(importResult.rows);
         })
      }
   }
   const onStartImport = () => {
      importRef.current?.click()
   }

   const onImportDone = async () => {
      setImportRows([])
      await client.refetchQueries({
         include: ['BrandItemsListMany']
      });
   }

   const onChangeState = async (rows: FlexBrandItemFragment[], state: BrandItemStateEnum) => {
      await SavingUI.process(async () => {
         for (const row of rows) {
            await changeState({
               variables: {
                  brandHouseId: brandHouseId,
                  id: row._id,
                  state: state
               }
            })
         }
      }, 'Done', 'Changing state, please wait')
   }

   const commands: FlexCommand<FlexBrandItemFragment>[] = [{
      id: 'publish',
      title: 'Publish to',
      icon: Storefront,
      children: data?.ShopMany?.map(shop => {
         return {
            id: 'publish_' + shop._id,
            title: shop.name || 'Shop',
            icon: Storefront,
            execute: async rows => {
               for (const row of rows) {
                  await pushBrandItem({
                     variables: {
                        brandItemId: row._id,
                        shopId: shop._id,
                        active: true
                     }
                  })
               }
            }
         }
      }) || []
   }, {
      id: 'unpublish',
      title: 'Unpublish from',
      icon: Storefront,
      children: data?.ShopMany?.map(shop => {
         return {
            id: 'unpublish_' + shop._id,
            title: shop.name || 'Shop',
            icon: Storefront,
            execute: async rows => {
               for (const row of rows) {
                  await pushBrandItem({
                     variables: {
                        brandItemId: row._id,
                        shopId: shop._id,
                        active: false
                     }
                  })
               }
            }
         }
      }) || []
   }, {
      id: 'export',
      title: 'Export to excel',
      icon: FileDownload,
      execute: async rows => {
         setExportIds(rows.map(row => row._id) || [])
      }
   }]

   if (isAdministrator) {
      commands.push({
         id: 'changeState',
         title: 'Change state',
         icon: EditAttributesOutlined,
         children: Object.keys(BrandItemStateEnum).map(key => ({
            execute: async rows => onChangeState(rows, key as BrandItemStateEnum),
            icon: EditAttributes,
            title: key,
            id: key
         }))
      })
      commands.push({
         id: 'multiedit',
         title: 'Bulk edit',
         icon: EditIcon,
         execute: async rows => {
            setEditing(rows.map(row => row._id))
         },
      })
      commands.push({
         id: 'imageoperations',
         title: 'Advanced',
         icon: EditAttributesOutlined,
         children: [
            {
               id: 'retouch',
               title: 'Retouch images',
               icon: ImageAspectRatioOutlined,
               execute: async rows => {
                  Dialogs.confirm({
                     title: 'Start retouch of images from selected products?',
                     subtitle: 'You will not be able to undo this operation.'
                  }, async () => {
                     await SavingUI.process(async () => {
                        await retouchImages({
                           variables: {
                              ids: rows.map(row => row._id)
                           }
                        })
                     }, 'Retouch started', 'Initializing retouch, please wait')
                  })
               }
            },
            {
               id: 'proforma',
               title: 'Create proforma order',
               icon: ContentPaste,
               execute: async rows => {
                  const shop = data.ShopMany?.find(shop => shop?.live) || data.ShopMany?.[0];
                  if (!shop) {
                     Dialogs.info({
                        title: 'No live shop',
                        subtitle: 'No live shop found, cannot create proforma invoice'
                     });
                     return;
                  }
                  Dialogs.confirm({
                     title: 'Confirm creating a proforma invoice ?',
                     subtitle: 'The selected items will be added to the order.'
                  }, async () => {
                     await SavingUI.process(async () => {
                        const address = {
                           name: 'Proforma',
                           add1: 'Proforma',
                           zipCode: '8310',
                           city: 'Tranbjerg',
                           country: 'DK',
                           mail: 'support@create2stay.com',
                           phone: '+45 42509988',
                           countryCode: 'DK'
                        };

                        const sortedRows = rows.sort(
                           (a, b) => (a.stockLocation || ("Z" + a._id))
                              .localeCompare(b.stockLocation || ("Z" + b._id))
                        );

                        // Base order ID. We'll append an index if we need multiple orders.
                        const orderIdBase = "Proforma-" + Date.now().toString();

                        // Define maximum lines per order
                        const MAX_LINES = 100;

                        // Split the sortedRows into chunks of 100
                        for (let i = 0; i < sortedRows.length; i += MAX_LINES) {
                           const chunk = sortedRows.slice(i, i + MAX_LINES);

                           // Generate a unique order ID for each chunk if there are multiple
                           const orderIndex = (i / MAX_LINES) + 1;
                           const orderId = orderIdBase + (orderIndex > 1 ? `-${orderIndex}` : '');

                           await createOrder({
                              variables: {
                                 order: {
                                    id: {externalId: orderId},
                                    billingAddress: address,
                                    shopId: shop._id,
                                    externalId: orderId,
                                    orderDate: new Date(),
                                    orderName: orderId,
                                    orderState: EnumOrder2OrderState.OPEN,
                                    carrierInput: {
                                       carrier: EnumOrder2CarrierInputCarrier.UPS,
                                       delivery: EnumOrder2CarrierInputDelivery.HOME,
                                       express: false
                                    },
                                    consumer: address,
                                    currency: shop.currency,
                                    proforma: true,
                                    fulState: EnumOrder2FulState.OPEN,
                                    payState: EnumOrder2PayState.UNPAID,
                                    shippingAddress: address,
                                    lines: chunk.map(row => ({
                                       id: {
                                          externalId: orderId + "-" + row._id
                                       },
                                       externalLineId: orderId + "-" + row._id,
                                       ean: row.itemEan,
                                       sku: row.itemSku,
                                       currency: shop.currency,
                                       discountedPrice: row.beforePrices?.[0]?.value || 0,
                                       salesPrice: row.beforePrices?.[0]?.value || 0,
                                       quantity: 1,
                                       discountedPricePreTax: row.beforePrices?.[0]?.value || 0
                                    }))
                                 }
                              }
                           });
                        }

                     }, 'Proforma order created', 'Creating, please wait');
                  });
               }
            },
            {
               id: 'adjustImages',
               title: 'Auto adjust images',
               icon: EditIcon,
               execute: async rows => {
                  await SavingUI.process(async () => {
                     for (const row of rows) {
                        await adjustImages({
                           variables: {
                              brandItemId: row._id
                           }
                        })
                     }
                  }, 'Adjust complete', 'Adjusting master images, please wait')

               },
            },
            {
               id: 'groupUpdate',
               title: 'Update masterdata group',
               icon: DeleteIcon,
               execute: async rows => {
                  Dialogs.confirm({
                     title: 'Are you sure you want to update masterdata for these items?',
                     subtitle: 'You will not be able to undo this operation.'
                  }, async () => {
                     await SavingUI.process(async () => {
                        const {data} = await updateGroup({
                           variables: {
                              brandItemIds: rows.map(row => row._id)
                           }
                        })
                        if ((data?.BrandItemGroupUpdate || []).length > 0) {
                           Dialogs.info({
                              title: 'Group update',
                              subtitle: 'Updated ' + (data?.BrandItemGroupUpdate?.length || 0) + ' items'
                           })
                        }
                     }, 'Done', 'Updating, please wait')
                  })
               }
            },
            {
               id: 'updateCache',
               title: 'Synchronize selected items',
               icon: ImageAspectRatioOutlined,
               execute: async rows => {
                  Dialogs.confirm({
                     title: 'Start a synchronization of selected items?',
                     subtitle: 'This will update external integrations data for the selected items.'
                  }, async () => {
                     await SavingUI.process(async () => {
                        for (const row of rows) {
                           await updateCache({
                              variables: {
                                 brandItemId: row._id
                              }
                           })
                        }
                     }, 'Done', 'Please wait')
                  })
               }
            },
         ]
      });
      commands.push({
         id: 'delete',
         title: 'Delete',
         icon: DeleteIcon,
         execute: async rows => {
            Dialogs.confirm({
               title: 'Delete the selected items?',
               subtitle: 'You will not be able to undo this operation.'
            }, async () => {
               await SavingUI.process(async () => {
                  for (const row of rows) {
                     await deleteBrandItem({
                        variables: {
                           brandItemId: row._id
                        }
                     })
                  }
               }, 'Deleted', 'Deleting, please wait')
            })
         }
      })
   }

   let languages = (data?.BrandSettings?.locales?.filter(locale => locale!.activeItems && locale!.locale)
      .map(locale => locale!.locale!)) || [];

   return (
      <Page>
         <PageHeader title={"Stock Items"}>
            {isAdministrator && (
               <input ref={importRef}
                      type="file"
                      id="importInputFile"
                      style={{display: 'none'}}
                      onChange={onSelectFile}
               />
            )}
            <Button onClick={() => setEditCampaign(true)}>Campaigns</Button>
            {isAdministrator && <Button onClick={onStartImport}>Import</Button>}
            {data?.BrandSettings?.useBrandItems && <Button variant={"contained"} onClick={onCreating}>Create</Button>}
         </PageHeader>

         <PageContent fullHeight>
            {importRows.length > 0 && (
               <Box>
                  <TitleText type={"h2"}>Importing items, please wait</TitleText>
                  <ImportItems searchField={importSearchField.current} languages={languages} rows={importRows}
                               onComplete={onImportDone}/>
               </Box>
            )}
            {importRows.length === 0 && (
               <BrandItemsList
                  listId={"brandStockItemList"}
                  brandHouseId={brandHouseId}
                  columnOptions={{id: true}}
                  onSelectItem={onSelectBrandItem}
                  commands={commands}
                  shops={(data?.ShopMany || []).map(shop => ({
                     _id: shop._id,
                     name: shop.name!
                  }))}
               />
            )}
         </PageContent>

         {creating && (
            <BrandItemDialog
               brandHouseId={brandHouseId}
               onCreateStockItem={onCreateStockItem}
               onCreateEmpty={onCreateEmpty}
               onCancel={onCancel}
            />
         )}

         {editCampaign && (
            <CampaignPricing onClose={() => setEditCampaign(false)} open={editCampaign} brandHouseId={brandHouseId}/>
         )}

         {editing.length > 0 && <BrandItemMultiEdit ids={editing} onClose={onCloseEditor}/>}
         {exportIds.length > 0 && <BrandItemExport language={"da"} currency={"DKK"} ids={exportIds}/>}
      </Page>
   );
}
