import {FlexFilter, FlexFilterValue} from "./FlexTypes";

export function buildGraphQLFilter(filters?: FlexFilter<any>[], values?: FlexFilterValue[]) {
   let totalFilter: any[] = [];
   filters?.forEach(filter => {
      const selected = values?.find(value => value.filterId === filter.id);
      let effectiveFilterAnd: any[] = [];
      let effectiveFilterOr: any[] = [];
      if (selected) {
         for (const selectedElement of selected.values) {
            const option = filter.options.find(option => option.label === selectedElement || option.id === selectedElement)
            if (option?.filter) {
               if (selected.requireAll) {
                  effectiveFilterAnd.push(option?.filter);
               } else {
                  effectiveFilterOr.push(option?.filter);
               }
            }
         }
      }
      if (effectiveFilterOr.length > 0) {
         totalFilter.push({
            OR: effectiveFilterOr
         });
      }
      if (effectiveFilterAnd.length > 0) {
         totalFilter.push({
            AND: effectiveFilterAnd
         });
      }
   });
   if (totalFilter.length > 0) {
      return {
         AND: totalFilter
      }
   } else {
      return {}
   }
}