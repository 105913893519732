import * as React from "react";
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";

export type PageCommandProps = {
   float?: boolean
}

const PageCommands: React.FC<PageCommandProps> = ({float, children}) => {
   const classes = useStyles();
   if(float) {
      return <Box sx={{
         position: 'absolute',
         right: 0
      }}>
         <Grid item xs>
            {children}
         </Grid>
      </Box>
   } else {
      return <Grid container className={classes.root}>
         <Grid item xs>
            {children}
         </Grid>
      </Grid>
   }
}


const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
         marginBottom: theme.spacing(1),
         width: '100%',
         display: 'flex',
         borderBottom: '1px solid #e0e0e0',
         textAlign: 'right'
      },
      cell: {}
   }),
);

export default PageCommands