import {useVoucherUsagesQuery, VoucherTransaction, VoucherTransactionEnumType} from "../../../Queries";
import {BodyText, Link, TitleText} from "../../../layout/Typography";
import {Box, LinearProgress, Step, StepLabel, Stepper} from "@mui/material";
import {DateFormat} from "../../../utility/DateFormat";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {ParamBrandHouse} from "../../../Parameters";
import {ConsumerPagePath} from "../../shop/shopdata/consumers/ConsumerPage";
import {TradeInPagePath} from "../../shop/tradeins/TradeInPage";

type VoucherUsagesProps = {
   voucherId: string
}

type VoucherDataItem = {
   transaction: VoucherTransaction
   date: Date
}

export const VoucherUsages = ({voucherId}: VoucherUsagesProps) => {
   const [items, setItems] = useState<VoucherDataItem[]>([]);
   const {brandHouseId} = useParams<ParamBrandHouse>()
   const {data} = useVoucherUsagesQuery({
      variables: {
         voucherId: voucherId
      }
   })
   const navigate = useNavigate();
   const voucher = data?.VoucherById

   useEffect(() => {
      const newItems: VoucherDataItem[] = [];
      for (const voucherTransaction of data?.VoucherTransactions || []) {
         newItems.push({
            transaction: voucherTransaction as VoucherTransaction,
            date: voucherTransaction!.date
         })
      }
      setItems(newItems.sort((a, b) => {
         return new Date(b.date).getTime() - new Date(a.date).getTime()
      }));

   }, [data])

   if (!data) {
      return <LinearProgress/>
   }

   return <div>
      <Box sx={{marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
         <TitleText sx={{marginTop: 0}} component={"div"} type={"h2"}>Timeline</TitleText>
      </Box>
      <Stepper orientation={"vertical"}>
         {!!voucher?.deleted && <Step>
             <StepLabel error icon={"D"}>
                 <div>{DateFormat.toReadable(voucher?.deleted)}</div>
                 <div>Voucher deleted</div>
             </StepLabel>
         </Step>
         }
         <div>{items.map((item, i) => {
            const elements: any[] = [];

            const transaction = item.transaction!;
            const getTransactionIcon = (type: VoucherTransactionEnumType) => {
               switch (type) {
                  case VoucherTransactionEnumType.USAGE:
                     return "U";
                  case VoucherTransactionEnumType.REFUND:
                     return "R";
                  case VoucherTransactionEnumType.ADJUST:
                     return "A";
                  case VoucherTransactionEnumType.ISSUED:
                     return "I";
                  default:
                     return "T";
               }
            };

            const getTransactionMessage = (transaction: VoucherTransaction) => {
               const amount = Math.round(transaction.amount * 10) / 10;
               const currency = voucher?.currency;

               switch (transaction.type) {
                  case VoucherTransactionEnumType.USAGE:
                     return <>Consumer used {!voucher?.passive && <>{Math.abs(amount)} {currency}</>} on&nbsp;
                        <a target={"_blank"} rel="noreferrer" href={transaction?.url || '#'}>order</a></>
                  case VoucherTransactionEnumType.REFUND:
                     return <>Issued a {amount} {currency} refund</>;
                  case VoucherTransactionEnumType.ADJUST:
                     return transaction.amount >= 0
                        ? <>{amount} {currency} was added to the voucher</>
                        : <>{Math.abs(amount)} {currency} was subtracted from the voucher</>;
                  case VoucherTransactionEnumType.ISSUED:
                     return <>{amount} {currency} credit issued to <Link
                        onClick={() => navigate(ConsumerPagePath(brandHouseId, voucher?.shopId, voucher?.consumerId))}>consumer</Link> for <Link
                        onClick={() => navigate(TradeInPagePath(brandHouseId, voucher?.shopId, voucher?.tradeInId))}>tradein</Link></>;
                  default:
                     return <>Transaction of {amount} {currency}</>;
               }
            };

            elements.push(<Step active={i === 0} key={'step_' + transaction?._id}>
               <StepLabel icon={getTransactionIcon(transaction.type as VoucherTransactionEnumType)}>
                  <div>{DateFormat.toReadable(transaction?.date)}</div>
                  <div>
                     {getTransactionMessage(transaction)}
                     {transaction.balance > 0 && transaction.type !== VoucherTransactionEnumType.ISSUED &&
                         <span style={{marginLeft: '8px'}}>
                              (Balance: {Math.round(transaction.balance * 10) / 10} {voucher?.currency})
                           </span>
                     }
                     {transaction.reason && <Box sx={{display: 'flex', flexDirection: 'row', gap: '6px'}}>
                         <BodyText type={"body1"}>{transaction.user?.name || 'System'}: </BodyText>
                        {transaction.reason &&
                            <BodyText sx={{fontWeight: 600}} type={"body1"}>{transaction.reason}</BodyText>}
                     </Box>}
                  </div>
               </StepLabel>
            </Step>);
            return elements
         })}</div>
      </Stepper>
   </div>
}