import * as React from "react";
import {FC} from "react";
import {
   FilterFindManyBrandProductInput,
   LocalizationEntry,
   ProductPaginationFragment,
   SortFindManyBrandProductInput,
   useBrandProductListPaginationLazyQuery,
   useBrandProductListSourcesQuery
} from "../../Queries";
import {FlexFilter, FlexListColumn, FlexSort, FlexTab} from "../../components/flexlist/FlexTypes";
import {Thumbnails} from "../../components/Thumbnails";
import {LinearProgress} from "@mui/material";
import {FlexList} from "../../components/flexlist/FlexList";

type BrandProductsListProps = {
   brandHouseId: string
   onProductSelect: (product: ProductPaginationFragment) => void
   defaultSearch?: string
   inDialog?: boolean
   onTabChange?: (groupId: string) => void
}

const PAGE_SIZE = 20;

export const BrandProductsList: FC<BrandProductsListProps> = ({brandHouseId, onProductSelect, inDialog, onTabChange}) => {
   const [fetchMore] = useBrandProductListPaginationLazyQuery();
   const {data} = useBrandProductListSourcesQuery({
      variables: {brandHouseId}
   })

   let columns: FlexListColumn<ProductPaginationFragment>[] = [{
      id: 'image',
      label: "Image",
      get: (product) => {
         return product._id
      },
      render: (id, product) => {
         let imageUrls = product?.imageUrls || [];
         return <Thumbnails size={100} imageUrls={imageUrls.filter(img => !!img) as string[]} maxImages={3}/>
      },
      width: 5
   }, {
      id: 'name',
      field: 'nameLocalized',
      label: "Name",
      render: (names: LocalizationEntry[], row) => {
         const firstValue = names?.find(name => !!name.value)
         const languages = names?.filter(name => !!name.value)?.map(name => name.locale).join(',')
         return <div>{firstValue?.value} ({languages})</div>
      },
      width: 20
   }, {
      id: 'style',
      field: 'style',
      label: "Style",
      render: (names: string, row) => {
         return <div>{names}</div>
      },
      width: 5
   }]

   const sortSpec: FlexSort<SortFindManyBrandProductInput>[] = [{
      id: 'CreatedAsc',
      label: 'Created (oldest first)',
      value: SortFindManyBrandProductInput._ID_ASC
   }, {
      id: 'CreatedDesc',
      label: 'Created (newest first)',
      value: SortFindManyBrandProductInput._ID_DESC
   }]

   const filters: FlexFilter<any>[] = [{
      id: 'groups',
      label: 'Groups',
      options: data?.BrandProductGroups?.map(group => {
         return {
            filter: {
               groupId: group._id
            },
            label: group.name
         }
      }) || []
   }]

   const tabsSpec: FlexTab[] = data?.BrandProductGroups?.map(group => {
      return {
         id: String(group._id),
         label: group.name,
         filters: [{
            filterId: 'groups',
            values: [group.name]
         }]
      }
   }) || [{
      id: 'all',
      label: 'All',
      filters: []
   }];

   const onRequestData = async (search: string | undefined, sort: string | undefined, page: number, filter: any) => {
      const effectiveFilter: FilterFindManyBrandProductInput = {
         ...filter,
         _operators: {
            brandHouseId: {
               ...!!brandHouseId ? {in: [brandHouseId]} : {ne: '601c19a2c6dac20032263343'}
            },
         }
      }

      const {data} = await fetchMore({
         variables: {
            page: page,
            sort: (sort as SortFindManyBrandProductInput) || SortFindManyBrandProductInput._ID_DESC,
            perPage: PAGE_SIZE,
            search: search,
            filter: effectiveFilter
         }
      });
      return {
         data: data?.BrandProductsPagination?.items || [],
         totalCount: data?.BrandProductsPagination?.count || 0
      }
   }

   const onSelectProduct = (product: ProductPaginationFragment) => {
      onProductSelect(product);
   }

   if (!data?.BrandProductGroups?.length) {
      return <LinearProgress/>
   }

   return <FlexList
      selections={true}
      globalId={"viewBrandProductList"}
      tabs={inDialog ? undefined : tabsSpec}
      itemsPerPage={PAGE_SIZE}
      columns={columns}
      initialTab={inDialog ? undefined : tabsSpec[0].id}
      filters={filters}
      onRequestData={onRequestData}
      sorts={sortSpec}
      options={{
         itemHeight: 105,
         height: inDialog ? 500 : undefined,
      }}
      onSelectRow={onSelectProduct}
      onTabChange={onTabChange}
   />

}
