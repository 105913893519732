import { FlexFilterValue } from "./FlexTypes";

/**
 * Utility functions for serializing and deserializing FlexList state to/from URL
 */

/**
 * Serialize filters to URL-friendly string
 * Format: filterId:value1,value2,value3|requireAll;filterId2:value1,value2|requireAll
 */
export function serializeFilters(filters: FlexFilterValue[]): string {
   if (!filters || filters.length === 0) return '';

   return filters.map(filter => {
      const requireAllParam = filter.requireAll ? '1' : '0';
      return `${filter.filterId}:${filter.values.join(',')}|${requireAllParam}`;
   }).join(';');
}

/**
 * Deserialize filters from URL string
 */
export function deserializeFilters(filterStr: string): FlexFilterValue[] {
   if (!filterStr) return [];

   return filterStr.split(';').map(filterPart => {
      const [filterWithValues, requireAllStr] = filterPart.split('|');
      const [filterId, valuesStr] = filterWithValues.split(':');
      const values = valuesStr ? valuesStr.split(',') : [];

      return {
         filterId,
         values,
         requireAll: requireAllStr === '1'
      };
   });
}

/**
 * Helper function to update search params without losing existing params
 */
export function updateSearchParams(
   searchParams: URLSearchParams,
   updates: Record<string, string | null>
): URLSearchParams {
   const newParams = new URLSearchParams(searchParams);

   for (const [key, value] of Object.entries(updates)) {
      if (value === null || value === '') {
         newParams.delete(key);
      } else {
         newParams.set(key, value);
      }
   }

   return newParams;
}

/**
 * Get URL parameters for FlexList state
 */
export function getFlexListURLParams(
   search: string,
   tabId: string | undefined,
   filters: FlexFilterValue[],
   sort: string | undefined
): Record<string, string | null> {
   return {
      search: search || null,
      tab: tabId || null,
      filters: filters.length > 0 ? serializeFilters(filters) : null,
      sort: sort || null
   };
}

/**
 * Apply URL parameters to FlexList state
 */
/**
 * Convert FlexFilterValue array to filterAnd and filterOr objects
 */
export function filtersToFilterObjects(filters: FlexFilterValue[]): {
   filterAnd: { [id: string]: string[] },
   filterOr: { [id: string]: string[] }
} {
   const filterAnd: { [id: string]: string[] } = {};
   const filterOr: { [id: string]: string[] } = {};

   for (const filter of filters) {
      if (filter.requireAll) {
         filterAnd[filter.filterId] = filter.values;
      } else {
         filterOr[filter.filterId] = filter.values;
      }
   }

   return { filterAnd, filterOr };
}

export function applyURLParamsToFlexList(searchParams: URLSearchParams) {
   const filters = deserializeFilters(searchParams.get('filters') || '');
   const { filterAnd, filterOr } = filtersToFilterObjects(filters);

   return {
      search: searchParams.get('search') || '',
      tabId: searchParams.get('tab') || undefined,
      filters,
      filterAnd,
      filterOr,
      sort: searchParams.get('sort') || undefined
   };
}